import React from "react";
import classNames from "classnames";

import * as classes from "./ResearchAndDevelopmentCombinationsDiagram.module.scss";

export default function ResearchAndDevelopmentCombinationsDiagram({className, ...props}) {
    return (
        <dl className={classNames(classes.container, className)} {...props} />
    );
}

function Definition(props) {
    return (
        <dt className={classes.definition} {...props} />
    );
}

function DescriptionContainer(props) {
    return (
        <dd className={classes.descriptionContainer} {...props} />
    );
}

function Description({className, ...props}) {
    return (
        <p className={classNames(classes.description, className)} {...props} />
    );
}

function Heading(props) {
    return (
        <h3 className={classes.heading} {...props} />
    );
}

ResearchAndDevelopmentCombinationsDiagram.Definition = Definition;
ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer = DescriptionContainer;
ResearchAndDevelopmentCombinationsDiagram.Description = Description;
ResearchAndDevelopmentCombinationsDiagram.Heading = Heading;
