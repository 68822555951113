import React from "react";
import {Helmet} from "react-helmet";

import ResearchAndDevelopmentCombinationsDiagram
    from "../../components/research-and-development/ResearchAndDevelopmentCombinationsDiagram";

export default function ResearchAndDevelopmentLiquidBiochipPage() {
    return (
        <article>
            <Helmet>
                <title>Биочип SER-2</title>
            </Helmet>
            <h2>Биочип SER-2</h2>
            <p>Стадия: Подготовка регистрационных документов</p>
            <p><strong>Панель антител — WT1, EpCAM, PAX8</strong></p>
            <h3>Назначение</h3>
            <p>Определение органопринадлежности опухолевых клеток в жидкостях из серозных полостей к раку яичников.</p>
            <h3>PAX8</h3>
            <p>Парный бокс-белок Pax-8 (PAX8) является членом семейства транскрипционных факторов PAX. Он важен для органогенеза во время эмбрионального развития почек, щитовидной железы и парамезонефральных протоков, дающих начало мочеполовым органам, включая семенные пузырьки, семявыносящие протоки, мочеточник, матку и маточные трубы. Хотя PAX8 является критическим для органогенеза, устойчивая экспрессия в нормальных почках, щитовидной железе, груди, семенных пузырьках, эпителии яичников, шейки матки и эндометрия предполагает роль в гомеостатическом контроле зрелых тканей. Иммуногистохимическое окрашивание выявило почти исключительную ядерную локализацию с низкой или незначительной цитоплазматической экспрессией. Из-за ограничительной экспрессии в нормальных тканях, Было высказано предположение, что PAX8 является чувствительным и специфическим маркером как для первичных, так и для метастатических опухолей, происходящих из вышеупомянутых органов и тканей.</p>
            <h3>WT1</h3>
            <p>Белок опухоли Вильмса (WT1) был идентифицирован в пролиферирующих мезотелиальных клетках, злокачественной мезотелиоме, карциноме яичников, гонадобластоме, нефробластоме и десмопластической опухоли мелких круглых клеток. Аденокарциномы легких редко окрашиваются положительно при использовании этого антитела.</p>
            <h3>EpCAM/BerEp4</h3>
            <p>Экспрессируется на поверхности клеточной мембраны и в цитоплазме практически всех эпителиальных клеток, за исключением самых плоских эпителиев, гепатоцитов, почечных проксимальных трубчатых клеток, желудочных париетальных клеток и миоэпителиальных клеток. Однако фокальную положительность можно наблюдать в базальном слое плоскоклеточного эпителия энтодермы (например, небных миндалин) и мезодермы (например, шейки матки). Нормальные мезотелиальные клетки отрицательны к EpCAM, но могут давать положительную реакцию в случае выраженных реактивных изменений.</p>
            <ResearchAndDevelopmentCombinationsDiagram>
                <ResearchAndDevelopmentCombinationsDiagram.Heading>
                    Варианты комбинаций — EpCAM, PAX8, WT1
                </ResearchAndDevelopmentCombinationsDiagram.Heading>
                <ResearchAndDevelopmentCombinationsDiagram.Definition>
                    EpCam +<br />
                    PAX8 +<br />
                    WT1 +
                </ResearchAndDevelopmentCombinationsDiagram.Definition>
                <ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                    <ResearchAndDevelopmentCombinationsDiagram.Description>
                        Наличие в образце эпителиальных клеток, характерных для рака яичников
                    </ResearchAndDevelopmentCombinationsDiagram.Description>
                </ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                <ResearchAndDevelopmentCombinationsDiagram.Definition>
                    EpCam -<br />
                    PAX8 -<br />
                    WT1 -
                </ResearchAndDevelopmentCombinationsDiagram.Definition>
                <ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                    <ResearchAndDevelopmentCombinationsDiagram.Description>
                        Реактивные изменения в клетках мезотелия
                    </ResearchAndDevelopmentCombinationsDiagram.Description>
                </ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
            </ResearchAndDevelopmentCombinationsDiagram>
        </article>
    );
}
